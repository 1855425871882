import { QUESTION_API } from "votesha-model";

export type EnvironmentConfig = {
  env: String;
  region: String;
  userPoolId: String;
  userPoolWebClientId: String;
  identityPoolId: String;
  endpoint: String;
  oauth: {
    domain: String;
    redirect: String;
  };
};

export const DevEnvironment: EnvironmentConfig = {
  env: "dev",
  region: "us-east-1",
  userPoolId: "us-east-1_VYkUZ2BVL",
  userPoolWebClientId: "3nun39luf4krl5gs2hajudie2u",
  identityPoolId: "us-east-1:d6f91119-5c71-4b7c-807b-01d49cc1bea1",
  endpoint: "https://dev.api.votesha.com",
  oauth: {
    domain: "dev-votesha.auth.us-east-1.amazoncognito.com",
    redirect: "exp://127.0.0.1:19000/--/",
  },
};

export const ProdEnvironment: EnvironmentConfig = {
  env: "prod",
  region: "us-east-1",
  userPoolId: "us-east-1_n27CooOcU",
  userPoolWebClientId: "5nl725gecgbjv5phtvtm0g3ffn",
  identityPoolId: "us-east-1:dd877dcc-4a44-42d3-9669-460f237639ad",
  endpoint: "https://api.votesha.com",
  oauth: {
    domain: "auth.votesha.com",
    // redirect: "exp://127.0.0.1:19000/--/",
    redirect: "votesha://",
  },
};

export function toAmplifyConfig(config: EnvironmentConfig) {
  return {
    API: {
      endpoints: [
        {
          name: QUESTION_API,
          region: config.region,
          endpoint: config.endpoint,
        },
      ],
    },
    Auth: {
      region: config.region,
      userPoolId: config.userPoolId,
      userPoolWebClientId: config.userPoolWebClientId,
    },
    Analytics: {
      // I could never get this error to go away https://github.com/aws-amplify/amplify-js/issues/3484 so I disabled Analytics
      // https://stackoverflow.com/questions/56680112/bad-request-is-returned-when-amplify-is-configured-for-hosted-ui-in-angular-6/56685607#56685607
      disabled: true,
    },
  };
}
