import { API } from "aws-amplify";
import { getOptions, getPayload } from "./DaoUtils";
import {
  Question,
  QuestionWithVote,
  RelatedAnswersStatsResponse,
  QuestionID,
  QuestionAPI,
  QuestionQuery,
  QUESTION_API,
  QuestionWithStats,
  VoteStats,
  QuestionSearchResponse,
  QuestionSearchRequest,
} from "votesha-model";

class QuestionDao implements QuestionAPI {
  async queryQuestions(query: QuestionQuery): Promise<QuestionWithVote[]> {
    function buildQuery() {
      let params = "tag=" + query.tag;
      if (query.limit && query.limit > 0) params += "&limit=" + query.limit;
      if (query.offset && query.offset > 0) params += "&offset=" + query.offset;
      if (query.showAnswered) params += "&answered=1";
      if (query.showNew) params += "&new=1";
      if (query.showSkipped) params += "&skipped=1";
      if (query.electionID) params += "&electionID=" + query.electionID;
      return params;
    }
    const url = "/questions?" + buildQuery();
    console.info("Getting questions", url);
    const response = await API.get(QUESTION_API, url, await getOptions());
    return getPayload(response);
  }

  async getQuestions(): Promise<Question[]> {
    const questions = await this.queryQuestions({
      showNew: true,
      showSkipped: true,
      showAnswered: true,
      tag: "",
      limit: 1000,
    });
    // Getting questions with votes is a waste. Switch this to return just question data
    // const response = await API.get(QUESTION_API, "//", await getOptions());
    // return getPayload<Question>(response);
    return questions.map((q) => q.question);
  }

  async getQuestion(id: QuestionID): Promise<QuestionWithStats> {
    const response = await API.get(
      QUESTION_API,
      "/question/" + id,
      await getOptions()
    );
    return getPayload<QuestionWithStats>(response);
  }

  async getVoteStats(id: QuestionID): Promise<VoteStats> {
    return {
      votes: !!id ? 1 : 2, // Mock
      answers: {},
    };
    // const response = await API.get(QUESTION_API, "/question/" + id + "/stats", await getOptions());
    // return getPayload<VoteStats>(response);
  }

  async getQuestionWithVote(id: QuestionID): Promise<QuestionWithVote> {
    const response = await API.get(
      QUESTION_API,
      "/question/" + id + "/vote",
      await getOptions()
    );
    return getPayload<QuestionWithVote>(response);
  }

  async updateQuestion(question: Question): Promise<Question> {
    const response = await API.post(
      QUESTION_API,
      "/question/" + question.id,
      await getOptions({
        body: question,
      })
    );
    return getPayload<Question>(response);
  }

  async insertQuestion(question: Question): Promise<Question> {
    const response = await API.put(
      QUESTION_API,
      "/question",
      await getOptions({
        body: question,
      })
    );
    return getPayload<Question>(response);
  }

  async deleteQuestion(questionID: QuestionID): Promise<boolean> {
    const response = await API.del(
      QUESTION_API,
      "/question/" + questionID,
      await getOptions()
    );
    return getPayload<boolean>(response);
  }

  async searchQuestions(
    request: QuestionSearchRequest
  ): Promise<QuestionSearchResponse[]> {
    console.warn("searchQuestions hasn't been implemented yet");
    return [];
  }

  async getQuestionRelatedAnswers(
    questionID: QuestionID
  ): Promise<RelatedAnswersStatsResponse> {
    const response = await API.get(
      QUESTION_API,
      "/question/" + questionID + "/relatedAnswers",
      await getOptions()
    );
    return getPayload(response);
  }
}

export default QuestionDao;
