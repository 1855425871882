import { API } from "aws-amplify";
import { getOptions, getPayload } from "./DaoUtils";
import {
  QuestionWithVote,
  Vote,
  QuestionID,
  UserID,
  QUESTION_API,
} from "votesha-model";

class VoteshaAPI {
  async getVote(questionID: QuestionID, userID: UserID): Promise<Vote> {
    const response = await API.get(
      QUESTION_API,
      `/vote/${questionID}/${userID}`,
      await getOptions()
    );
    return getPayload(response);
  }

  async postVote(vote: Vote): Promise<QuestionWithVote> {
    const response = await API.post(
      QUESTION_API,
      "/vote",
      await getOptions({
        body: vote,
      })
    );
    return getPayload(response);
  }

  async rebuildFromVotes(): Promise<{}> {
    const response = await API.get(
      QUESTION_API,
      "/redis/rebuildFromVotes",
      await getOptions()
    );
    return getPayload<boolean>(response);
  }

  async stubTest(): Promise<{}> {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        const result = Math.random() > 0.5;
        if (!result) {
          const randomError = new Error("Random error");
          reject(randomError);
        } else {
          resolve();
        }
      }, 500);
    });
  }
}

export default VoteshaAPI;
